<template>
  <section>
    <title-section
      title="Categorías de publicaciones"
      returnText="Regresar"
      :returnToFunction="returnPrevView"
    >
      <div class="level-item">
        <button class="button button_micro_dark" @click="activeModalAddCategoryParent = true">
          AGREGAR CATEGORÍA
        </button>
      </div>
    </title-section>
    <categories-main
      :activeModalAddCategory="activeModalAddCategoryParent"
      @close-modal="activeModalAddCategoryParent = false"
    />
  </section>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Categories',
  components: {
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    CategoriesMain: () => import('@/components/Publications/Categories/CategoriesMain.vue')
  },
  data () {
    return {
      activeModalAddCategoryParent: false
    }
  },
  methods: {
    ...mapActions(['returnPrevView'])
  }
}
</script>

<style lang="scss" scoped>

</style>
